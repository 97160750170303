import { createStore } from 'vuex'
import axios from 'axios'
import {
  // isLocal
  isLocal,
  isBestGoldenOfferCom,
  isBodyMagicSnapPro,
  isUBodyRu,
  isStepVibeFitCom,
  isHypeColorQubeCom,
  isStayFitUBodyPro,
  isUstretchFitCom,
  isUstretchSetCom,
  isSmartDeepRu
} from '@/utils/stand'

// import the auto exporter
import modules from './modules'

const getConfigUrl = () => {
  switch (true) {
    case isLocal():
    case isBestGoldenOfferCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=bestgoldenoffer.com'
    case isStayFitUBodyPro():
      return 'https://config.artdeepfilters.com/api/v1/config?key=stayfitubody.pro'
    case isUBodyRu():
      return 'https://config.pwhelp.ru/api/v1/config?key=u-body.ru'
    case isStepVibeFitCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=stepvibefit.com'
    case isHypeColorQubeCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=hypecolorqube.com'
    case isBodyMagicSnapPro():
      return 'https://config.artdeepfilters.com/api/v1/config?key=body.magicsnap.pro'
    case isUstretchFitCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=ustretchfit.com'
    case isUstretchSetCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=ustretchset.com'
    case isSmartDeepRu():
      return 'https://config.pwhelp.ru/api/v1/config?key=smartdeep.ru'
  }
}

export const config = axios.create({
  baseURL: getConfigUrl()
})

export const cdp = axios.create({
  baseURL: 'https://cdp.sigmamobi.com/api/'
})

const store = createStore({
  modules
})

export function useStore() {
  return store
}

export default store
